import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
  Place,
  LocalShipping,
  Info,
  Upload,
  Close,
  Edit,
  Construction,
  Photo
} from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import { twMerge } from 'tailwind-merge';
import axios from 'axios';
import { Modal } from '@mui/material';
import { UserContext } from '../../App';
import Timeline from '../../components/Timeline/Timeline';
import PhotoFeed from '../../components/PhotoFeed';
import Select from 'react-select';

const READ_API_URL = process.env.REACT_APP_READ_API_URL;
const WRITE_API_URL = process.env.REACT_APP_WRITE_API_URL;

const Shipment = () => {
  // @ts-ignore
  const { user } = useContext(UserContext);
  const shipmentId = useParams<{ shipmentId: string }>().shipmentId;
  const [shipment, setShipment] = useState<any | null>(null);
  const [timelineLoading, setTimelineLoading] = useState(false);
  const [timelineData, setTimelineData] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [showResolveModal, setShowResolveModal] = useState(false);
  const [showOsdModal, setShowOsdModal] = useState(false);
  const [file, setFile] = useState<any | null>(null);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resolveSuccess, setResolveSuccess] = useState(false);
  const [osdSuccess, setOsdSuccess] = useState(false);
  const [photosData, setPhotosData] = useState<any[]>([]);
  const [photosLoading, setPhotosLoading] = useState(false);
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [issueResolved, setIssueResolved] = useState(false);
  const [osdResponse, setOsdResponse] = useState<any | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await axios.get(
        `${READ_API_URL}/api/v1/admin/shipments/${shipmentId}`
      );
      setShipment(response.data);
      setLoading(false);
    };

    if (!!user) {
      fetchData();
    }
  }, [shipmentId, user]);

  useEffect(() => {
    const fetchData = async () => {
      setTimelineLoading(true);
      setPhotosLoading(true);

      const timelineResponse = await axios.get(
        `${READ_API_URL}/api/v1/admin/shipments/${shipmentId}/get_timeline`
      );
      setTimelineData(timelineResponse.data);
      setTimelineLoading(false);

      // Has to run in sequence so we don't blow up s3 connection
      const photosResponse = await axios.get(
        `${READ_API_URL}/api/v1/admin/shipments/${shipmentId}/photos`
      );
      setPhotosData(photosResponse.data.photos);
      setPhotosLoading(false);
    };

    if (!!shipment) {
      fetchData();
    }
  }, [shipmentId, shipment]);
  console.log(timelineData);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setPhotosLoading(true);
  //     const response = await axios.get(
  //       `${READ_API_URL}/api/v1/admin/shipments/${shipmentId}/photos`
  //     );
  //     setPhotosData(response.data.photos);
  //     setPhotosLoading(false);
  //   };

  //   if (!!shipment) {
  //     fetchData();
  //   }
  // }, [shipmentId, shipment]);

  useEffect(() => {
    setIssueResolved(timelineData.some((item) => item.status === 'Resolved'));
  }, [timelineData]);

  const handlePdfUpload = async () => {
    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = async () => {
      setUploading(true);
      // @ts-ignore
      const base64 = reader.result.split(',')[1];
      try {
        await axios.post(
          `${WRITE_API_URL?.replace('web-btb', 'api-btb')}/api/v1/admin/upload_bol_pdf`,
          {
            bol_id: shipment.bol_id,
            pdf_base64: base64
          }
        );
        setUploadSuccess(true);
        setUploading(false);
      } catch (error) {
        console.error('Error uploading PDF:', error);
      }
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    const fetchData = async () => {
      const interval = setInterval(async () => {
        const response = await axios.get(
          `${READ_API_URL}/api/v1/admin/shipments/poll_consignee_signature/${osdResponse.result_id}`
        );

        if (response.data.ready) {
          clearInterval(interval);
          setTimelineLoading(true);
          const response = await axios.get(
            `${READ_API_URL}/api/v1/admin/shipments/${shipmentId}/get_timeline`
          );
          setTimelineData(response.data);
          setTimelineLoading(false);
        }
      }, 500);
    };

    if (osdResponse?.result_id) {
      fetchData();
      // setInterval(() => {
      //   console.log(response);
      //   // if response is ready break interval
      // }, 500);
    }
  }, [osdResponse]);

  const handleFileChange = (event: any) => {
    setFile(event.target.files[0]);
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    setFile(event.dataTransfer.files[0]);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setFile(null);
  };

  const handleResolveModalClose = () => {
    setShowResolveModal(false);
    setResolveSuccess(false);
  };

  const handleResolveSubmit = async (e: any) => {
    e.preventDefault();

    try {
      await axios.post(`${WRITE_API_URL}/api/v1/admin/shipments/${shipmentId}/note`, {
        note: e.target.note.value
      });

      setResolveSuccess(true);

      setTimelineLoading(true);
      const response = await axios.get(
        `${READ_API_URL}/api/v1/admin/shipments/${shipmentId}/get_timeline`
      );
      setTimelineData(response.data);

      setTimeout(() => {
        setTimelineLoading(false);
        setShowResolveModal(false);
        setResolveSuccess(false);
      }, 2000);
    } catch (error) {
      console.error('Error resolving issue:', error);
    }
  };

  const handleOsdModalClose = () => {
    setShowOsdModal(false);
  };

  const handleOsdSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${WRITE_API_URL}/api/v1/admin/shipments/${shipmentId}/sign_with_osd`,
        {
          osd_data: e.target.osd.value,
          subject_to_count: e.target['subject-to-count'].checked
        }
      );
      setOsdResponse(response.data);

      setOsdSuccess(true);
      setTimeout(() => {
        setShowOsdModal(false);
        setOsdSuccess(false);
      }, 2000);
    } catch (error) {
      console.error('Error signing BOL:', error);
    }
  };

  const handlePhotoModalClose = () => {
    setShowPhotoModal(false);
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center mt-4 w-full">
        <CircularProgress />
        <p className="mt-2">Loading...</p>
      </div>
    );
  }

  if (!shipment) {
    return <div>Sorry, shipment not found!</div>;
  }

  // hack for getting username from email until we pass it to the user object from the backend
  const username = user.email.match(/^[^@+._]+/)[0];

  return (
      <div className="p-5 w-full">
        <h1 className="text-2xl mb-3">Shipment # {shipment.tnumber}</h1>
        <div className="flex gap-5 flex-wrap">
          <InfoCard
              title="Reference #s"
              icon={<Info className="text-[#5db1ff]"/>}
              color="#5db1ff"
              data={[
                {label: 'Shipment #', value: shipment.tnumber},
                {label: 'BOL #', value: shipment.tnumber}
                // { label: 'PO #', value: '09283' },
                // { label: 'Appointment #', value: '72390' }
              ]}
          />
          <InfoCard
              title="Ship From"
              icon={<Place/>}
              data={[{value: shipment.ship_from}]}
          />
          <InfoCard
              title="Carrier"
              color="#fb6e52"
              icon={<LocalShipping className="transform -scale-x-100 text-[#fb6e52]"/>}
              data={[{value: shipment.carrier_name}]}
          />
          <InfoCard
              title="Ship To"
              icon={<Place className="text-[#8cc474]"/>}
              color="#8cc474"
              data={[{value: shipment.ship_to}]}
          />
          <InfoCard
              title="Checked In"
              icon={<Place className="text-[#8cc474]"/>}
              color="#8cc474"
              data={[{value: shipment.check_in}]}
          />
        </div>
        <div className="mt-5">
          <ShipmentButton
              label="Upload PDF"
              icon={<Upload fontSize="small"/>}
              onClick={() => setShowModal(true)}
          />
          <ShipmentButton
              label="Sign BOL"
              icon={<Edit fontSize="small"/>}
              onClick={() => setShowOsdModal(true)}
          />
          <ShipmentButton
              label="Resolve Check-in/out Issue"
              icon={<Construction fontSize="small"/>}
              onClick={() => setShowResolveModal(true)}
              disabled={issueResolved}
          />
        </div>

        <div className="mt-5">
         <ShipmentButton
              label="Send Reminder Email"
              icon={<Upload fontSize="small"/>}
              onClick={async () => {
                if (window.confirm("Are you sure?")) {
                  await axios.post(
                      `https://gcp-api.freightroll.com/api/v1/send_reminder_email`,
                      {
                        shipmentID:shipment.tnumber,
                        assignmentPassword: "testpass",
                        to_email: user.email,
                        to_name: user.name,
                        bcc_email:"chris+test@freightroll.com"
                      }
                  );
                }
              }}
          />
        </div>


        <div className="flex flex-col gap-5 lg:flex-row">
          <div className="shadow bg-[#FBFBFB] w-full lg:w-1/2 mt-5">
            <div className="p-2 shadow text-sm bg-white">Shipment Timeline</div>
            <Timeline data={timelineData} loading={timelineLoading}/>
          </div>
          <div className="shadow bg-[#FBFBFB] w-full lg:w-1/2 mt-5">
            <div className="p-2 shadow text-sm bg-white flex  items-center">
              <Photo fontSize="small" className="mr-1"/>
              Photos
              <button
                  className="bg-sky-600 hover:bg-sky-500 text-white p-1 rounded ml-auto"
                  onClick={() => setShowPhotoModal(true)}
              >
                Attach Photo
              </button>
            </div>
            <PhotoFeed photos={photosData} loading={photosLoading}/>
          </div>
        </div>

        <Modal
            open={showModal}
            onClose={handleModalClose}
            className="flex justify-center items-center"
        >
          <div className="w-96 bg-white border p-5 min-h-48 relative flex flex-col">
            <Close
                className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
                onClick={handleModalClose}
            />
            <h1 className="text-xl mb-5">Upload PDF</h1>
            {uploadSuccess ? (
                <div className="bg-green-100 p-2 border-slate-300 border-[1px] text-sm mb-3">
                  PDF uploaded successfully!
                </div>
            ) : (
                <>
                  <input type="file" accept=".pdf" onChange={handleFileChange}/>
                  <div
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                      className="p-5 mt-3 border-dashed border-slate-300 border-2"
                  >
                    {file ? file.name : 'Or drop PDF file here'}
                  </div>
                  <Select
                      options={[
                        {value: 'bol', label: 'BOL'},
                        {value: 'customs', label: 'Customs'},
                        {value: 'coa', label: 'COA'},
                        {value: 'other', label: 'Other'}
                      ]}
                      placeholder="PDF Type"
                      className="mt-3"
                  />
                  <button
                      className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded ml-auto mt-3"
                      onClick={handlePdfUpload}
                      disabled={uploading}
                  >
                    {uploading ? 'Uploading...' : 'Upload PDF'}
                  </button>
                </>
            )}
          </div>
        </Modal>
        {!issueResolved && (
            <Modal
                open={showResolveModal}
                onClose={handleResolveModalClose}
                className="flex justify-center items-center"
            >
              <div className="w-96 bg-white border p-5 min-h-48 relative flex flex-col">
                <Close
                    className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
                    onClick={handleResolveModalClose}
                />
                <h1 className="text-xl mb-5">Resolve Check-in/out Issue</h1>
                {resolveSuccess ? (
                    <div className="bg-green-100 p-2 border-slate-300 border-[1px] text-sm mb-3">
                      Resolution Submitted!
                    </div>
                ) : (
                    <>
                      <h1>Please note resolution:</h1>
                      <form onSubmit={handleResolveSubmit} className="flex flex-col">
                  <textarea
                      className="border-[1px] rounded p-1"
                      placeholder="Issue"
                      id="note"
                      name="note"
                      rows={6}
                  />
                        <div className="mt-3 flex justify-between">
                          <button
                              className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded"
                              onClick={handleResolveModalClose}
                          >
                            Cancel
                          </button>
                          <button
                              className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded"
                              type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </>
                )}
              </div>
            </Modal>
        )}
        <Modal
            open={showOsdModal}
            onClose={handleOsdModalClose}
            className="flex justify-center items-center"
        >
          <div className="w-96 bg-white border p-5 min-h-48 relative flex flex-col">
            <Close
                className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
                onClick={handleOsdModalClose}
            />
            <h1 className="text-xl">Sign BOL</h1>
            {osdSuccess ? (
                <div className="bg-green-100 p-2 border-slate-300 border-[1px] text-sm mb-3 mt-5">
                  BOL Signed!
                </div>
            ) : (
                <>
                  <p className="capitalize mb-5">
                    <span className="font-bold">Name:</span> {username || user.email}
                  </p>
                  <h1>Report OS&D:</h1>
                  <form onSubmit={handleOsdSubmit} className="flex flex-col">
                <textarea
                    className="border-[1px] rounded p-1"
                    placeholder="OS&D"
                    id="osd"
                    name="osd"
                    rows={4}
                />
                    <label className="cursor-pointer">
                      <input
                          type="checkbox"
                          id="subject-to-count"
                          name="subject-to-count"
                          value="subject-to-count"
                          className="mr-2"
                          defaultChecked
                      />
                      Subject to count
                    </label>
                    <div className="mt-3 flex justify-between">
                      <button
                          className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded"
                          onClick={handleOsdModalClose}
                      >
                        Cancel
                      </button>
                      <button
                          className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded"
                          type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </>
            )}
          </div>
        </Modal>
        <Modal
            open={showPhotoModal}
            onClose={handlePhotoModalClose}
            className="flex justify-center items-center"
        >
          <div className="w-96 bg-white border p-5 min-h-48 relative flex flex-col">
            <Close
                className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
                onClick={handlePhotoModalClose}
            />
            <h1 className="text-xl mb-5">Attach Photo</h1>

            <input type="file" accept=".pdf" onChange={() => console.log('Adding Photo')}/>
            <div className="p-5 mt-3 border-dashed border-slate-300 border-2">
              {file ? file.name : 'Or drop photo here'}
            </div>
            <button
                className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded ml-auto mt-3"
                onClick={() => console.log('Attach Photo')}
                disabled={uploading}
            >
              Attach Photo
            </button>
          </div>
        </Modal>
      </div>
  );
};

interface InfoCardProps {
  title: string;
  icon: any;
  color?: string;
  data: {
    label?: string;
    value: string | number;
  }[];
}

const InfoCard = ({title, icon, color, data}: InfoCardProps) => {
  return (
      <div className="bg-[#FBFBFB] w-52 shadow border-slate-300 border-x-[1px] text-sm p-5">
        <div className="flex mb-3 items-center">
          <div className="mr-2">{icon}</div>
          <h3 className={`text-[${color}] text-xl`}>{title}</h3>
        </div>
        <div className="flex flex-col">
          {data.map((item, i) => {
          if (typeof item.value === 'boolean' && item.value) {
            item.value = 'Yes';
          }
          return (
            <div key={i} className="flex justify-between">
              {item.label && <span className="font-bold">{item.label}</span>}
              <span>{item.value}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface ShipmentButtonProps {
  label: string;
  icon: any;
  onClick: () => void;
  disabled?: boolean;
}

const ShipmentButton = ({ label, icon, onClick, disabled }: ShipmentButtonProps) => {
  const handleClick = () => {
    if (disabled) {
      return;
    }
    onClick();
  };

  return (
    <button
      className={twMerge(
        'border-slate-300 border-[1px] border-l-0 first:border-l-[1px] p-2 shadow bg-white hover:bg-[#e6e6e6] text-sm',
        disabled ? 'bg-[#f9f9f9] hover:bg-[#f9f9f9] text-gray-300 cursor-default' : ''
      )}
      onClick={handleClick}
    >
      {icon}
      <span className="ml-1">{label}</span>
    </button>
  );
};
export default Shipment;
